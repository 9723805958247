// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application'
import 'admin-lte/plugins/jquery/jquery'
import 'admin-lte/plugins/jquery-ui/jquery-ui'
import 'admin-lte/plugins/popper/popper'
import 'admin-lte/plugins/bootstrap/js/bootstrap'
import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars'
import 'admin-lte/plugins/select2/js/select2.full'
import 'admin-lte/build/js/AdminLTE'

$(function () {
  $(".select2").select2();
  $('.select2bs4').select2({ theme: 'bootstrap4' });
});
